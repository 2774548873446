import React from "react";
import "./Certifications.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function Certifications() {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 124 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2
        }
    };
    return (
        <>
            


            <div className="container mt-5">
                <div className="text-center">
                    <h3 className="head-style inf fnt" >Certificates</h3>
                </div>
                <div className=" mt-5 certificatslide">
                    <div className="slider ">
                        <div className="slide-track ">
                        <div className="slide">
                                <img className="img-certificates" src="./images/certificates/fssailogo.png"></img>
                            </div>
                            <div className="slide">
                                <img className="img-certificates mt-3 " src="./images/certificates/fssc.png"></img>
                            </div>

                            <div className="slide">
                                <img className="img-certificates" src="./images/certificates/foodchainnongmo.jpg"></img>
                            </div>
                            <div className="slide">
                                <img className="img-certificates mt-3" src="./images/certificates/gmp.jpg"></img>
                            </div>
                            <div className="slide">
                                <img className="img-certificates" src="./images/certificates/klbdkashar.jpg"></img>
                            </div>
                            <div className="slide">
                                <img className="img-certificates mt-5" src="./images/certificates/usfda.png"></img>
                            </div>
                            <div className="slide">
                                <img className="img-certificates" src="./images/certificates/halal.png"></img>
                            </div>
                            {/*<div className="slide">
                                <img className="img-certificates mt-3 " src="./images/certificates/fssc.png"></img>
                            </div>

                            <div className="slide">
                                <img className="img-certificates" src="./images/certificates/foodchainnongmo.jpg"></img>
                            </div>
                            <div className="slide">
                                <img className="img-certificates mt-3" src="./images/certificates/gmp.jpg"></img>
                            </div>
                            <div className="slide">
                                <img className="img-certificates" src="./images/certificates/klbdkashar.jpg"></img>
                            </div>*/}
                            

                        </div>
                    </div>
                </div>

            </div>

        </>
    );
}

export default Certifications;